



























































































































import { computed, defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { useStorage } from "@vueuse/core";
import { minutesToMilliseconds } from "date-fns";
import { DateTime } from "klokwerk";
import { z } from "zod";
import ErrorAlert from "@/components/ErrorAlert.vue";
import type { IomodelsLidVoorFeed } from "@/lib/backend/club.api";
import { LS_LIVEFEED_COACHINGSGROEP } from "@/lib/constants/localstorage";
import { getCoachName } from "@/lib/getCoachName";
import { queries } from "@/lib/query/queries";
import { logger } from "@/logger";
import { getTimeOfDay } from "@/utils/date";
import { getIconName, getMemberImage } from "@/utils/images";

const SECTIONS = [
	{
		label: "In de club",
		filterRule: (lid: IomodelsLidVoorFeed) => {
			const before = new DateTime().setHours((current) => current.hours - 1);
			const timestamp = new DateTime(lid.timestamp);

			return timestamp >= before;
		},
	},
	{
		label: "Waarschijnlijk al weg",
		filterRule: (lid: IomodelsLidVoorFeed) => {
			const before = new DateTime().setHours((current) => current.hours - 1);
			const timestamp = new DateTime(lid.timestamp);

			return timestamp < before;
		},
	},
] as const;

export default defineComponent({
	components: { ErrorAlert },
	setup() {
		const coachingsGroep = useStorage<"30%" | "70%" | "all">(
			LS_LIVEFEED_COACHINGSGROEP,
			"30%",
			localStorage,
			{
				serializer: {
					read: (value) => {
						const result = z.enum(["30%", "70%", "all"]).default("30%").safeParse(value);

						if (result.success) {
							return result.data;
						} else {
							return "30%";
						}
					},
					write: JSON.stringify,
				},
				onError: logger.error,
			},
		);

		const { data: coaches } = useQuery(queries.coach.all());

		const { isLoading, data, error } = useQuery({
			...queries.overview.liveFeed(),
			refetchInterval: minutesToMilliseconds(1),
		});

		const members = computed(
			() =>
				data.value?.filter((member) => {
					if (coachingsGroep.value === undefined || coachingsGroep.value === "all") {
						return member;
					}

					const isSameGroep = coachingsGroep.value === member.coachingsGroep;

					if (coachingsGroep.value !== "70%") {
						return isSameGroep;
					}

					return isSameGroep || member.coachingsGroep !== "30%";
				}) ?? [],
		);

		return {
			getMemberImage,
			getIconName,
			getTimeOfDay,
			DateTime,
			SECTIONS,
			isLoading,
			error,
			members,
			coachingsGroep,
			coaches,
			getCoachName,
		};
	},
});
